<template>
  <div class="ResponseTime">
    <div class="placeholder" v-if="!responseTime"></div>
    <div :class="classes"
         :style="styles"
         v-else>
      {{ responseTime | responseTime }} sec
    </div>
  </div>
</template>

<script>
export default {
  props: {
    responseTime: {
      required: true
    },
    fontSize: {
      required: false,
      type: Number,
      default: 16
    }
  },

  computed: {
    classes () {
      let classes = []

      if (!this.responseTime) {
        return []
      }

      if (this.responseTime < this.dangerResponseTimeThreshold &&
          this.responseTime >= this.warningResponseTimeThreshold) {
        classes.push('warning')
      }

      if (this.responseTime >= this.dangerResponseTimeThreshold) {
        classes.push('danger')
      }

      return classes
    },

    styles () {
      return {
        'font-size': this.fontSize + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .ResponseTime {
    .placeholder {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: #f9f9f9;
    }

    .warning {
      border-bottom: 2px solid map-get($colors, warning);
    }

    .danger {
      border-bottom: 2px solid map-get($colors, red-2);
    }
  }
</style>
