<template>
  <div class="MonitorNodes container">
    <nodes-header />
    <div class="node-list">
      <div v-for="node in nodes" :key="node.id" class="node-list-item">
        <node-item
          :monitor="monitor"
          :node="node"
          :user="user"
          @toggle="onNodeToggle($event).then(() => {refreshMap()})"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NodesHeader from './MonitorNodesHeader.vue'
import NodeItem from './MonitorNodesItem.vue'
import monitorsApi from '@/api/monitorsApi.js'
import { bus } from '@/main'

export default {
  components: {
    NodesHeader,
    NodeItem
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  methods: {
    async onNodeToggle (payload) {
      if (payload.isActive) {
        await monitorsApi.enableNode(this.monitor.id, payload.node)
      }

      if (!payload.isActive) {
        await monitorsApi.disableNode(this.monitor.id, payload.node)
      }

      this.$emit('toggle', payload)
    },
    refreshMap () {
      bus.$emit('refreshMap')
    }
  },

  computed: {
    ...mapState('nodes', [
      'nodes'
    ]),
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorNodes {
    @media (max-width: 1264px) {
      padding: 0;
    }

    .node-list {
      @media (max-width: 1264px) {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
      }

      &-item {
        margin-bottom: 20px;

        @media (max-width: 1264px) {
          width: 50%;
          display: flex;
          padding-right: 25px;
          padding-left: 25px;
        }

        @media (max-width: 1130px) {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
