<template>
  <div class="Uptime">
    <div class="placeholder" v-if="!uptime"></div>
    <div :class="classes"
         :style="styles"
         v-else>
      {{ uptime | uptime }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    uptime: {
      required: true
    },
    fontSize: {
      required: false,
      type: Number,
      default: 16
    }
  },

  computed: {
    classes () {
      let classes = []

      if (!this.uptime) {
        return []
      }

      if (this.uptime <= this.warningUptimeThreshold &&
          this.uptime > this.dangerUptimeThreshold) {
        classes.push('warning')
      }

      if (this.uptime <= this.dangerUptimeThreshold) {
        classes.push('danger')
      }

      return classes
    },

    styles () {
      return {
        'font-size': this.fontSize + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Uptime {
    .placeholder {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      background-color: #f9f9f9;
    }

    .warning {
      border-bottom: 2px solid map-get($colors, warning);
    }

    .danger  {
      border-bottom: 2px solid map-get($colors, red-2);
    }
  }
</style>
